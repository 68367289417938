export let appConfiguration = {
  prefix : "ctl",
  ENVIRONMENT : "DEV",  //Possible-Values ['LOCAL', 'DEV', 'COMMERCIAL']

  LOCAL_CATALYST_Url : "",
  DEV_CATALYST_Url : "https://dev.catalystservice.anydatapoint.com/",
  COMMERCIAL_CATALYST_Url : "https://catalystservice.anydatapoint.com/",
  // DEV_DTS_Url : "http://devdtsservice.anydatapoint.com/"
  DEV_DTS_Url : "http://dtsservice.anydatapoint.com/"
}


export let IMAGES_BASE_URL = "http://dev.cdn01.anydatapoint.com/";

export let copyRight = "2018"
export let INCName = "Any Data Point Inc."

