import { Component, OnInit } from '@angular/core';
import {copyRight, INCName} from '../../providers/config'
import {version} from '../../providers/version'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {

  copyRightYear;
  INCName;
  version;

  // set the currenr year
  year: number = new Date().getFullYear();

  constructor() {
    this.copyRightYear = copyRight +"-"+ (new Date()).getFullYear();
    this.version = version;
    this.INCName = INCName;
  }

  ngOnInit() {
  }

}
